export const getDefaultSize = sizes => {
  return !sizes.length
    ? {}
    : sizes.find(({ defaultValue }) => defaultValue === 'S') || sizes.find(({ size }) => size === 2) || sizes[0];
};

export const addPriceToProducts = (products, prices, sizes) =>
  products
    .sort((a, b) => (a.ordering < b.ordering ? -1 : 1))
    .map(data => {
      let defaultCode = data.productCode;
      let defaultPrice = 0;
      if (data.type !== '1') {
        const productVariants = sizes.filter(
          ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
        );
        if (productVariants.length > 0) {
          defaultCode = getDefaultSize(productVariants).productCode;
        }
      }
      if (defaultCode) {
        defaultPrice = (prices.find(({ product }) => product === defaultCode) || { price: 0 }).price;
      }
      return {
        ...data,
        defaultCode,
        defaultPrice,
      };
    });

export const getPrice = (prices, productCode) => {
  return (prices.find(item => item.product === productCode) || {}).price || 0;
};

export const getGroups = (
  product,
  productGroups,
  optionGroups,
  singleOptions,
  recipes,
  sizes,
  menuType = 'mcmenu',
  selectedSize,
  stocks,
) => {
  const productVariant = sizes.find(item => item.defaultValue === 'S') || {};
  const groups = productGroups
    .filter(({ classCode, item, state }) => state === 'A' && classCode === product.classCode && item === product.item)
    .map(data => {
      const group = optionGroups.find(({ id }) => id === data.optionGroup);
      const options = singleOptions
        .filter(
          ({ optionGroup, productCode, size, state }) =>
            state === 'A' &&
            optionGroup === data.optionGroup &&
            size === (menuType === 'mcmenu' ? selectedSize || productVariant.size || 0 : 0) &&
            !stocks.includes(productCode),
        )
        .map(option => {
          if (group.showRecipe) {
            // eslint-disable-next-line no-param-reassign
            option.recipes = recipes.filter(
              ({ ingredient, productCode, state }) =>
                state === 'A' && productCode === option.productCode && !stocks.includes(ingredient),
            );
          }
          return option;
        });
      return { ...data, group, options };
    })
    .sort((a, b) => a.group.ordering - b.group.ordering);
  return groups;
};

export const getMappedRecipes = (recipes = [], prices) => {
  return recipes.map(({ id, ingredient, quantity, description }) => ({
    recipe: id,
    quantity: quantity || 0,
    price: getPrice(prices, ingredient),
    description: description || '',
  }));
};

export const getProductInfo = (data, prices, sizes) => {
  let defaultPrice = 0;
  let defaultCode = 0;
  if (data && prices && sizes) {
    defaultCode = data.productCode;
    if (data.type !== '1') {
      const productVariants = sizes.filter(
        ({ classCode, item, state }) => state === 'A' && classCode === data.classCode && item === data.item,
      );
      if (productVariants.length > 0) {
        defaultCode = getDefaultSize(productVariants).productCode;
      }
    }
    if (defaultCode !== 0) {
      defaultPrice = (prices.find(({ product }) => product === defaultCode) || {}).price || 0;
    }
  }
  return { productCode: defaultCode, price: defaultPrice / 100 };
};

export const isPossibleGift = (cart, giftProduct) => {
  return (
    cart.findIndex(c => c.item.productCode === +giftProduct || (c.size && c.size.productCode === +giftProduct)) !== -1
  );
};

const currencies = {
  gt: {
    singular: 'Quetzal',
    plural: 'Quetzales',
    symbol: 'Q',
  },
  sv: {
    singular: 'Dólar',
    plural: 'Dólares',
    symbol: '$',
  },
  hn: {
    singular: 'Lempira',
    plural: 'Lempiras',
    symbol: 'L',
  },
  ni: {
    singular: 'Córdova',
    plural: 'Córdovas',
    symbol: 'C$',
  },
};
const locations = {
  gt: {
    lat: 14.628434,
    lng: -90.522713,
  },
  sv: {
    lat: 13.8029939,
    lng: -88.9053364,
  },
  hn: {
    lat: 14.7503821,
    lng: -86.241341,
  },
  ni: {
    lat: 12.8691653,
    lng: -85.1411896,
  },
};
const codes = {
  us: 1,
  gt: 502,
  sv: 503,
  hn: 504,
  ni: 505,
};
const countryNames = {
  us: 'United State',
  gt: 'Guatemala',
  sv: 'El salvador',
  hn: 'Honduras',
  ni: 'Nicaragua',
};
const callCenter = {
  gt: '1770',
  hn: '+504 22761770',
  ni: '+505 18001770',
  sv: '+503 25099999',
};
export const getCurrency = () => {
  return currencies[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getLocations = () => {
  return locations[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getPhoneCode = () => {
  return codes[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getCountry = () => {
  return countryNames[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getCallCenter = () => {
  return callCenter[process.env.REACT_APP_COUNTRY || 'gt'];
};
export const getGoogleAddressProperties = address_components => {
  const params = {};
  const country = address_components.find(({ types }) => types.includes('country'));
  if (country) params.country = country.long_name;
  const state = address_components.find(({ types }) => types.includes('administrative_area_level_1'));
  if (state) params.state = state.long_name;
  const postalCode = address_components.find(({ types }) => types.includes('postal_code'));
  if (postalCode) params.postalCode = postalCode;
  const city =
    address_components.find(({ types }) => types.includes('locality')) ||
    address_components.find(({ types }) => types.includes('sublocality_level_1'));
  if (city) params.city = city.long_name;
  return params;
};
