/* eslint-disable jsx-a11y/iframe-has-title */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { v1 as uuidv1 } from 'uuid';
import { Icon, Spin } from 'antd';
import Geocode from 'react-geocode';
import {
  addressActionCreators,
  catalogActionCreators,
  connectAddress,
  connectCatalog,
  connectCustomer,
  connectOrder,
  connectRegion,
  connectRestaurant,
  customerActionCreators,
  orderActionCreators,
  restaurantActionCreators,
} from 'core';
import { ArrowBack } from '@material-ui/icons';
import FoodMenu from 'components/Main/Order/FoodMenu';
import FoodList from 'components/Main/Order/FoodList';
import ProductSearch from 'components/Main/Order/ProductSearch';
import Cart from 'components/Main/Order/Cart';
import CheckoutBilling from 'components/Main/Order/CheckoutBilling';
import CheckoutCC from 'components/Main/Order/CheckoutCC';
import SingleProductDetail from 'components/Main/Order/SingleProductDetail';
import {
  addPriceToProducts,
  getCallCenter,
  getCurrency,
  getGoogleAddressProperties,
  getMappedRecipes,
  getPrice,
} from 'utilities/common';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import BusytimeModal from 'components/Main/Order/BusytimeModal';
import { getPaymentError } from '../../../utilities/paymentErrors';
import ErrorModal from '../../../components/Main/Order/ErrorModal';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class Order extends PureComponent {
  constructor(props) {
    super(props);
    const deviceFingerId = uuidv1();
    this.state = {
      pageType: 'cart',
      productId: null,
      catalogId: null,
      products: null,
      isEditOrder: false,
      checkoutInfo: {},
      errMsg: '',
      availableCatalogs: [],
      subCatalogId: null,
      subCatalog: [],
      isVisibleResetModal: false,
      isLoading: false,
      paymentId: -1,
      paymentMethods: [],
      deviceFingerId,
      breakfast: [],
      dinner: [],
      dayPart: '',
      restaurants: [],
      isBusytimeModal: false,
      isErrorModal: false,
    };
    const imported = document.createElement('script');
    imported.src = `https://h.online-metrix.net/fp/tags.js?org_id=${
      process.env.REACT_APP_ENV === 'dev' ? '1snn5n9w' : 'k8vif92e'
    }&session_id=visanetgt_mcdonaldsots${deviceFingerId}`;
    document.head.appendChild(imported);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);
  }

  componentDidMount() {
    const { superCataloges, catalogId, dueTo, selectedAddress } = this.props;
    if (!selectedAddress || (!selectedAddress.restaurant && !selectedAddress.belongsToDeliveryArea)) {
      this.props.history.push('/ots/order/new');
      return;
    }
    if (superCataloges && superCataloges.length !== 0) {
      const inactiveCataloges = [];
      promisify(this.props.getScheduleSuperCatalogs, {
        dueTo,
        restaurant: selectedAddress.restaurant,
      }).then(res => {
        this.setState({ availableCatalogs: res });
        for (let i = 0; i < superCataloges.length; i += 1) {
          if (res.findIndex(r => `${r.id}` === `${superCataloges[i].id}`) === -1) {
            inactiveCataloges.push(superCataloges[i]);
          }
        }
        const newSuperCataloges = [...res];
        if (newSuperCataloges.length !== 0) {
          this.setState({ catalogId: catalogId || newSuperCataloges[0].id });
          this.getProducts(catalogId || newSuperCataloges[0].id);
        }
      });
    }
    promisify(this.props.getRestaurantList, {})
      .then(res => {
        this.setState({ restaurants: res.data.rows });
      })
      .catch(e => {
        console.log('e', e);
      });
    this.getPaymentMethods();
    this.getSpecialOrders();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    const { partDay } = this.props;
    let tempDinner = [];
    let tempBreakfast = [];
    await promisify(this.props.getDayPartSuperCatalogs, {
      daypart: 'morning',
    }).then(res => {
      if (res.superClasses) {
        this.setState({
          breakfast: res.superClasses.rows,
        });
        tempBreakfast = res.superClasses.rows;
        if (res.superClasses.rows && res.superClasses.rows.length !== 0) {
          this.getProducts(res.superClasses.rows[0].id);
        }
      }
    });
    await promisify(this.props.getDayPartSuperCatalogs, {
      daypart: 'afternoon',
    })
      .then(res => {
        if (res.superClasses) {
          this.setState({
            dinner: res.superClasses.rows,
          });
          tempDinner = res.superClasses.rows;
        }
      })
      .then(() => this.handleDayPart(partDay));

    if (tempBreakfast.length === 0 && tempDinner.length !== 0) {
      this.setState({ dayPart: 'dinner' });
      this.getProducts(tempDinner[0].id);
    }
  };

  handleDayPart = dayPart => {
    const { breakfast, dinner } = this.state;
    this.setState({ dayPart });
    if (dayPart === 'breakfasts' && breakfast.length !== 0) {
      this.setState({ catalogId: breakfast[0].id });
      this.getProducts(breakfast[0].id);
    } else if (dayPart === 'dinner' && dinner.length !== 0) {
      this.setState({ catalogId: dinner[0].id });
      this.getProducts(dinner[0].id);
    }
  };

  getPaymentMethods = () => {
    const { selectedAddress } = this.props;

    promisify(this.props.getPaymentMethods, {
      id: selectedAddress.customer,
    }).then(res => {
      if (res && res.rows) {
        this.setState({
          paymentMethods: res.rows,
        });
      }
    });
  };

  getSpecialOrders = () => {
    const { selectedAddress } = this.props;

    promisify(this.props.getSpecialOrders, {
      filter: `filter=customer eq ${selectedAddress.customer}`,
    }).then(() => {});
  };

  handleSingleDetail = id => {
    this.setState({ pageType: 'single', productId: id, isEditOrder: false });
  };

  handleUpdateSingleDetail = (id, cartIndex) => {
    this.setState({ pageType: 'single', productId: id, isEditOrder: true, cartIndex });
  };

  handleShowCart = () => {
    this.setState({ pageType: 'cart' });
  };

  handleUpdateProduct = () => {
    this.setState({ pageType: 'cart' });
  };

  handleBillingPage = () => {
    const country = process.env.REACT_APP_COUNTRY || 'gt';
    if (country !== 'ni' && country !== 'sv') {
      this.setState({ pageType: 'billing' });
    } else {
      this.setState({ pageType: 'billingCC' });
    }
  };

  handleBillingCCPage = values => {
    this.setState({ checkoutInfo: { ...values }, pageType: 'billingCC' });
  };

  handleOrderConfirm = values => {
    const { checkoutInfo, paymentId, deviceFingerId } = this.state;
    const { cart, selectedAddress, prices, dueTo, note, nitInfo, gif_card, discountAmount } = this.props;
    const { linkId, paymentType: cardType } = values;
    let totalAmount = 0;
    let tempCardType = '';

    this.setState({
      checkoutInfo: { ...checkoutInfo, ...values },
      isLoading: true,
    });
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
      });
    }
    tempCardType = discountAmount >= totalAmount ? 'cash' : cardType;

    const contents = cart.map(product => {
      const { aggregate, cookieNote, customized, item, selectedGroups, totalCount: quantity, size } = product;

      const options = [];
      Object.keys(selectedGroups).forEach(key => {
        if (!selectedGroups[key]) return;
        options.push(
          ...Object.keys(selectedGroups[key]).map(optionKey => {
            const optionCustomized = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'I')
              : [];
            const optionAggregate = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'A')
              : [];
            return {
              option: selectedGroups[key][optionKey].id,
              ingredients: getMappedRecipes(optionCustomized, prices),
              aggregates: getMappedRecipes(optionAggregate, prices),
              price: getPrice(prices, selectedGroups[key][optionKey].productCode),
              optionDetail: { ...selectedGroups[key][optionKey] },
            };
          }),
        );
      });
      return {
        item: item.id,
        size: size.id,
        quantity,
        agent: 0,
        cookingNote: cookieNote || undefined,
        ingredients: getMappedRecipes(customized, prices),
        aggregates: getMappedRecipes(aggregate, prices),
        options,
      };
    });
    const params = {
      channel: 'OTS',
      customer: selectedAddress.customer,
      restaurant: selectedAddress.restaurant,
      deliveryAddress: selectedAddress,
      paymentAmount: Math.round(Math.max(totalAmount - discountAmount, 0) * 100),
      purchaseAmount: Math.round(totalAmount * 100),
      availableAmount: Math.round(
        tempCardType === 'card' || tempCardType === 'visa-link' ? totalAmount * 100 : (values.cash_amount || 0) * 100,
      ),
      type: dueTo ? 'S' : 'I',
      dueTo: dueTo || undefined,
      contents: contents.map(c => {
        return {
          ...c,
          options: c.options.map(o => {
            return {
              option: o.option,
              aggregates: o.aggregates,
              ingredients: o.ingredients,
              price: o.price,
            };
          }),
        };
      }),
      deliveryNote: note || undefined,
      deviceFingerprintId: deviceFingerId,
      ignoreMinAmount: true,
    };
    if (gif_card) {
      params.giftCard = gif_card || undefined;
      params.discountAmount = (discountAmount || 0) * 100;
    }
    params.paymentMethod =
      tempCardType === 'card'
        ? {
            type: 'C',
            customer: selectedAddress.customerList,
          }
        : tempCardType === 'visa-link'
        ? {
            type: 'VL',
            customer: selectedAddress.customerList,
            cashAmount: Math.max(totalAmount - discountAmount, 0),
            linkId,
          }
        : {
            type: 'S',
            customer: selectedAddress.customerList,
            cashAmount: values.cash_amount || 0,
          };
    if (tempCardType === 'card') {
      if (paymentId && paymentId !== -1) {
        params.paymentMethod.id = paymentId;
      } else {
        params.paymentMethod.number = values.card_number.replace(/ /g, '');
        params.paymentMethod.expirationYear = values.card_year;
        params.paymentMethod.expirationMonth = values.card_month;
        // params.paymentMethod.securityCode = values.card_code.trim();
      }
    }
    if (!selectedAddress.addressCountry) {
      Geocode.fromLatLng(selectedAddress.lat, selectedAddress.long).then(
        async response => {
          const addressInfo = getGoogleAddressProperties(response.results[0].address_components);
          await promisify(this.props.createMyAddress, {
            id: selectedAddress ? selectedAddress.id : null,
            postalCode: addressInfo.postalCode || null,
            addressCity: addressInfo.city || null,
            addressState: addressInfo.state || null,
            addressCountry: addressInfo.country || null,
          })
            .then(() => {})
            .catch(error => {
              console.log('error', error);
            });
        },
        error => {
          console.error(error);
        },
      );
    }
    const country = process.env.REACT_APP_COUNTRY || 'gt';
    if (country === 'gt') {
      // eslint-disable-next-line no-nested-ternary
      params.taxMethod =
        checkoutInfo.taxMethod === 'nit'
          ? 1
          : checkoutInfo.taxMethod === 'dpi'
          ? 2
          : checkoutInfo.taxMethod === 'passport'
          ? 3
          : -1;
      if (checkoutInfo.taxMethod === 'passport') {
        params.passportData = {
          passportNumber: (checkoutInfo.passport || '').length === 0 ? 'CF' : checkoutInfo.passport,
          passportCustomerFullName:
            (checkoutInfo.passport_full_name || '').length === 0 ? 'Consumidor Final' : checkoutInfo.passport_full_name,
        };
      }
      if (checkoutInfo.taxMethod === 'dpi') {
        params.dpiData = {
          dpiNumber: (checkoutInfo.dpi || '').length === 0 ? 'CF' : checkoutInfo.dpi,
          dpiCustomerFullName:
            (checkoutInfo.dpi_full_name || '').length === 0 ? 'Consumidor Final' : checkoutInfo.dpi_full_name,
        };
      }
      if (checkoutInfo.taxMethod === 'nit') {
        params.nit = nitInfo.validate && nitInfo.validate === 'Valid' ? checkoutInfo.nit_number || 'C/F' : 'C/F';
        if (params.nit) {
          params.nit = params.nit.trim();
        }
        params.billingName = nitInfo.name || 'Consumidor Final';
      }
    } else if (country === 'sv') {
      params.billingName = checkoutInfo.billing_name || undefined;
    } else if (country === 'hn') {
      params.nit = checkoutInfo.rnt_number ? checkoutInfo.rnt_number || 'C/F' : 'C/F';
      params.billingName = checkoutInfo.billing_name || undefined;
    }
    promisify(this.props.checkMyOrder, { params })
      .then(() => {
        promisify(this.props.createMyOrder, { params })
          .then(async res => {
            this.setState({ isLoading: false });
            promisify(this.props.setInitialCatalog, { cart: [] }).then(() => {
              promisify(this.props.setInitialOrder, {
                order: res.data,
              }).then(() => {
                this.props.history.push('/ots/order/confirm');
              });
            });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            if (error.status === 406) {
              if (error.data.type === 'WeeklyOrderLimitError') {
                toast.error({
                  title: this.props.intl.formatMessage(
                    {
                      id: `${error.data.type} {Phone}`,
                    },
                    {
                      Phone: getCallCenter(),
                    },
                  ),
                });
              } else if (error.data.type === 'MonthlyCardRegisterationLimitError') {
                toast.error({
                  title: this.props.intl.formatMessage(
                    {
                      id: `${error.data.type} {Phone}`,
                    },
                    {
                      Phone: getCallCenter(),
                    },
                  ),
                });
              } else if (error.data.type === 'PaymentError') {
                const errorId = getPaymentError(error.data.details[0]);

                toast.error({
                  title: this.props.intl.formatMessage({ id: `${errorId}` }),
                });
              } else if (error.data.type === 'VisaLinkPaymentError') {
                  this.setState({ isErrorModal: true });
              } else {
                toast.error({
                  title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
                });
              }
            } else {
              this.setState({
                errMsg: error && error.data ? this.props.intl.formatMessage({ id: `${error.data.type}` }) : '',
              });
            }
          });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error) {
          if (error.status === 406) {
            if (error.data.type === 'MinimumPurchaseError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Currency}{MinimumPurchase}`,
                  },
                  {
                    Currency: getCurrency().symbol,
                    MinimumPurchase: error.data.details[0].minimumPurchase / 100,
                  },
                ),
              });
            } else if (error.data.type === 'MaximumPurchaseError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Currency}{MaximumPurchase}`,
                  },
                  {
                    Currency: getCurrency().symbol,
                    MaximumPurchase: error.data.details[0].maximumPurchase / 100,
                  },
                ),
              });
            } else if (error.data.type === 'WeeklyOrderLimitError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Phone}`,
                  },
                  {
                    Phone: getCallCenter(),
                  },
                ),
              });
            } else if (error.data.type === 'MonthlyCardRegisterationLimitError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Phone}`,
                  },
                  {
                    Phone: getCallCenter(),
                  },
                ),
              });
            } else if (error.data.type === 'BusyServiceError') {
              this.setState({ isBusytimeModal: true });
            } else {
              toast.error({
                title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
              });
            }
          } else {
            toast.error({
              title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
            });
          }
        }
      });
  };

  handleChooseCatalog = id => {
    this.setState({ catalogId: id });
    this.getProducts(id);
    promisify(this.props.setInitialCatalog, { catalogId: id });
  };

  handleChooseSubCatalog = id => {
    this.setState({ subCatalogId: id });

    const { cataloges, products, prices, sizes } = this.props;
    if (cataloges && products && prices && sizes) {
      const matchedProducts = products.filter(product => {
        return `${product.classCode}` === `${id}`;
      });
      const updatedProducts = addPriceToProducts(matchedProducts, prices, sizes);
      this.setState({ products: updatedProducts });
    }
  };

  getProducts = catalogId => {
    const { cataloges, products, prices, sizes } = this.props;
    if (cataloges && products && prices && sizes) {
      const matchedCataloges = cataloges
        .filter(item => `${item.superClassCode}` === `${catalogId}` && item.id !== catalogId)
        .sort((a, b) => a.ordering - b.ordering);

      if (matchedCataloges.length !== 0) {
        this.setState({
          subCatalog: matchedCataloges,
          subCatalogId: matchedCataloges[0].id,
        });
        const matchedProducts = products.filter(product => {
          return `${matchedCataloges[0].id}` === `${product.classCode}`;
        });
        const updatedProducts = addPriceToProducts(matchedProducts, prices, sizes);
        this.setState({ products: updatedProducts });
        this.handleChooseSubCatalog(matchedCataloges[0].id);
      } else {
        this.setState({
          subCatalog: [],
          subCatalogId: null,
          products: [],
        });
      }
    }
  };

  handleShowResetConfirm = () => {
    this.setState({ isVisibleResetModal: true });
  };

  handleClearCart = () => {
    promisify(this.props.setInitialCatalog, { cart: [] });
    this.setState({ isVisibleResetModal: false, pageType: 'cart' });
  };

  handleBack = () => {
    const { selectedAddress } = this.props;
    this.props.history.push({
      pathname: '/ots/order/new',
      state: {
        selectedAddress,
      },
    });
  };

  handlePaymentSelect = id => {
    this.setState({ paymentId: id });
  };

  deletePayment = id => {
    this.setState({ paymentId: -1 });
    promisify(this.props.deletePaymentMethod, { id }).then(() => {
      this.getPaymentMethods();
    });
  };

  render() {
    const {
      subCatalogId,
      pageType,
      productId,
      cartIndex,
      isEditOrder,
      subCatalog,
      paymentMethods,
      paymentId,
      deviceFingerId,
      breakfast,
      dinner,
      dayPart,
      restaurants,
      isBusytimeModal,
    } = this.state;
    const { catalogId, prices, sizes, customerList, selectedAddress, cart, specialOrders, dueTo } = this.props;
    let customerInfo = {};
    if (customerList && customerList.length !== 0) {
      const index = customerList.findIndex(c => c.id === selectedAddress.customer);
      if (index !== -1) {
        customerInfo = customerList[index];
      }
    }

    const country = process.env.REACT_APP_COUNTRY || 'gt';
    let restaurantInfo = {};
    if (selectedAddress && selectedAddress.restaurant) {
      restaurantInfo = restaurants.find(r => r.id === selectedAddress.restaurant) || {};
    }
    return (
      <div className="category_layout">
        <noscript>
          <iframe
            src={`https://h.online-metrix.net/fp/tags?org_id=${
              process.env.REACT_APP_ENV === 'dev' ? '1snn5n9w' : 'k8vif92e'
            }&session_id=visanetgt_mcdonaldsots${deviceFingerId}`}
            style={{ width: 100, height: 100, border: 0, position: 'absolute', top: -5000 }}
          />
        </noscript>
        <div className="main_content scrollbar">
          <div className="left_panel scrollbar">
            <div className="content_header">
              <div className="back_btn" onClick={this.handleBack}>
                <ArrowBack />
              </div>
              <div>
                <p className="p-medium">
                  <strong>{`${customerInfo.firstName || ''} ${customerInfo.lastName || ''}`}</strong>
                </p>
                <p className="p-medium">{`${selectedAddress.address || ''}/${customerInfo.phone || ''}`}</p>
                {restaurantInfo.description && dueTo && (
                  <p className="p-medium store_service_time">
                    {restaurantInfo.timeService === '00' ? (
                      <span>
                        Lo sentimos, no tenemos servicio a domicilio para el restaurant seleccionado por el momento.
                      </span>
                    ) : (
                      <>
                        Tiempo estimado de entrega en este momento del restaurante:{' '}
                        <span>{restaurantInfo.description || ''}</span>, es de{' '}
                        <span>{restaurantInfo.timeService} minutos</span>
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>
            <FoodMenu
              breakfast={breakfast}
              dayPart={dayPart}
              dinner={dinner}
              onChooseCatalog={this.handleChooseCatalog}
              onChooseSubCatalog={this.handleChooseSubCatalog}
              onSelectDayPart={this.handleDayPart}
              selectedCatalogId={this.state.catalogId || catalogId}
              selectedSubCatalogId={subCatalogId || (subCatalog.length !== 0 ? subCatalog[0].id : -1)}
              subCatalog={subCatalog}
              superCataloges={dayPart === 'breakfasts' ? breakfast : dinner}
            />
            <FoodList
              availableCatalogs={this.state.availableCatalogs}
              isAuthorized
              onSingleDetail={this.handleSingleDetail}
              products={this.state.products}
              selectedCatalogId={this.state.catalogId || catalogId}
              specialOrders={specialOrders}
              superCataloges={dayPart === 'breakfasts' ? breakfast : dinner}
            />
            <ProductSearch
              isExistOrder={cart && cart.length !== 0}
              onResetCart={this.handleShowResetConfirm}
              onSingleDetail={this.handleSingleDetail}
              products={addPriceToProducts(this.props.products || [], prices || [], sizes || [])}
              restaurantId={this.props.selectedAddress.restaurant}
              selectedCatalogId={this.state.catalogId || catalogId}
            />
          </div>
          <div className="right_panel scrollbar">
            <Spin indicator={antIcon} spinning={this.state.isLoading}>
              {pageType === 'cart' && (
                <Cart onContinue={this.handleBillingPage} onSingleDetail={this.handleUpdateSingleDetail} />
              )}
              {pageType === 'billing' && country !== 'ni' && country !== 'sv' && (
                <CheckoutBilling onBack={this.handleShowCart} onContinue={this.handleBillingCCPage} />
              )}
              {pageType === 'billingCC' && (
                <CheckoutCC
                  deletePayment={this.deletePayment}
                  errMsg={this.state.errMsg}
                  handlePaymentSelect={this.handlePaymentSelect}
                  onBack={country !== 'ni' && country !== 'sv' ? this.handleBillingPage : this.handleShowCart}
                  onContinue={this.handleOrderConfirm}
                  paymentId={paymentId}
                  paymentMethods={paymentMethods}
                  restaurantInfo={restaurantInfo}
                />
              )}
              {pageType === 'single' && (
                <SingleProductDetail
                  cartIndex={cartIndex}
                  id={productId}
                  isEditOrder={isEditOrder}
                  onBack={this.handleShowCart}
                  onUpdateProduct={this.handleUpdateProduct}
                />
              )}
            </Spin>
          </div>
        </div>
        <DeleteConfirmModal
          isOpenModal={this.state.isVisibleResetModal}
          onCancel={() => this.setState({ isVisibleResetModal: false })}
          onOk={this.handleClearCart}
          subtitle="Todos los productos seleccionados se eliminar del carrito actual, ¿deseas conitnuar?"
          title="¿Deseas limpiar el carrito?"
        />
        <BusytimeModal
          deliveryAddress={selectedAddress}
          isOpenModal={isBusytimeModal}
          onCancel={() => this.setState({ isBusytimeModal: false })}
          onOk={() => this.setState({ isBusytimeModal: false })}
          subtitle="Por favor, elige una nueva fecha y hora de entrega para el pedido, debido a que la hora previamente seleccionada ya no se encuentra disponible."
          title="Oops... la hora de entrega se ha modificado"
        />
        <ErrorModal
          isOpenModal={this.state.isErrorModal}
          onCancel={() => this.setState({ isErrorModal: false })}
          onOk={() => this.setState({ isErrorModal: false })}
          subtitle="Aún no se ha efectuado el pago"
          title="Error en el pago con Visa"
        />
      </div>
    );
  }
}

Order.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object,
  catalogId: PropTypes.number,
  superCataloges: PropTypes.array,
  cataloges: PropTypes.array,
  products: PropTypes.array,
  sizes: PropTypes.array,
  prices: PropTypes.array,
  cart: PropTypes.array,
  selectedAddress: PropTypes.object,
  dueTo: PropTypes.string,
  partDay: PropTypes.string,
  note: PropTypes.string,
  specialOrders: PropTypes.array,
  nitInfo: PropTypes.object,
  gif_card: PropTypes.string,
  discountAmount: PropTypes.number,
  customerList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setInitialCatalog: PropTypes.func.isRequired,
  checkMyOrder: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  createMyOrder: PropTypes.func.isRequired,
  getScheduleSuperCatalogs: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  getSpecialOrders: PropTypes.func.isRequired,
  getDayPartSuperCatalogs: PropTypes.func.isRequired,
  createMyAddress: PropTypes.func.isRequired,
  getRestaurantList: PropTypes.func.isRequired,
};

Order.defaultProps = {
  history: {},
  catalogId: 0,
  superCataloges: [],
  cataloges: [],
  products: [],
  sizes: [],
  prices: [],
  cart: [],
  selectedAddress: {},
  customerList: [],
  dueTo: '',
  note: '',
  specialOrders: [],
  nitInfo: {},
  gif_card: '',
  discountAmount: 0,
  partDay: '',
};

const mapStateToProps = ({ catalog, address, order, customer }) => ({
  catalogId: catalog.catalogId,
  superCataloges: catalog.superCataloges,
  cataloges: catalog.cataloges,
  products: catalog.products,
  prices: catalog.prices,
  sizes: catalog.sizes,
  cart: catalog.cart,
  selectedAddress: address.selectedAddress,
  dueTo: order.dueTo,
  nitInfo: order.nitInfo,
  note: order.note,
  specialOrders: order.specialOrders,
  gif_card: order.gif_card,
  discountAmount: order.discountAmount,
  partDay: order.partDay,
  customerList: customer.list,
});

const mapDispatchToProps = dispatch => {
  const { setInitialCatalog, getScheduleSuperCatalogs, getDayPartSuperCatalogs } = catalogActionCreators;
  const { setInitialOrder, checkMyOrder, createMyOrder, getSpecialOrders } = orderActionCreators;
  const { createMyAddress } = addressActionCreators;
  const { deletePaymentMethod } = customerActionCreators;
  const { getRestaurantList } = restaurantActionCreators;

  return bindActionCreators(
    {
      setInitialCatalog,
      getScheduleSuperCatalogs,
      getDayPartSuperCatalogs,
      checkMyOrder,
      setInitialOrder,
      createMyOrder,
      getSpecialOrders,
      createMyAddress,
      deletePaymentMethod,
      getRestaurantList,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectAddress(mapStateToProps, mapDispatchToProps),
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectRegion(mapStateToProps, undefined),
  connectRestaurant(undefined, mapDispatchToProps),
  connectCustomer(mapStateToProps, mapDispatchToProps),
)(Order);
