// @flow

import { isEmpty, set } from 'lodash';

export async function restService({ api, third_party, method, params }) {
  const headers = {};

  let path;

  if (process.env.REACT_APP_ENV !== 'prod') {
    path = `${process.env.REACT_APP_DEVELOPMENT_API}${api}`;
  } else {
    path = `${process.env.REACT_APP_PRODUCTION_API}${api}`;
  }

  if (third_party) {
    path = api;
  }

  set(headers, 'Accept', 'application/json');
  set(headers, 'Content-Type', 'application/json');

  const reqBody = {
    method,
    headers,
    credentials: 'include',
  };

  if (!isEmpty(params)) {
    reqBody.body = JSON.stringify(params);
  }
  return fetch(path, reqBody)
    .then(response => {
      return response.text().then(text => {
        return text ? { status: response.status, data: JSON.parse(text) } : { status: response.status };
      });
    })
    .catch(error => {
      return {
        result: 'error',
        error,
      };
    });
}
