import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { Search } from '@material-ui/icons';
import { Icon, Popover, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import logoImg from 'assets/img/logo.svg';
import logoutImg from 'assets/img/logout.svg';
import { addressActionCreators, authActionCreators, connectAddress, connectAuth } from 'core';
import { promisify } from 'utilities';

const Content = ({ agent, onLogOut }) => {
  return (
    <div className="account_info_wrapper">
      <p className="p-medium name">{`${agent.firstName || ''} ${agent.lastName || ''}`}</p>
      <p className="p-small email">{agent.email || ''}</p>
      <div className="footer" onClick={onLogOut}>
        <img alt="logout" src={logoutImg} />
        <p className="p-medium">
          <FormattedMessage id="Sign off" />
        </p>
      </div>
    </div>
  );
};

Content.propTypes = {
  agent: PropTypes.object.isRequired,
  onLogOut: PropTypes.func.isRequired,
};

class NavBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageType: 'new',
    };
  }

  handleNavigation = type => {
    this.setState({ pageType: type });
    if (type === 'new') {
      promisify(this.props.setInitialAddress, { selectedAddress: {} });
      this.props.history.push('/ots/order/new');
    } else {
      this.props.history.push('/ots/claim');
    }
  };

  handleLogOut = () => {
    promisify(this.props.logOut, {})
      .then(() => {
        this.props.history.push('/');
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  render() {
    const { agent } = this.props;
    const { pageType } = this.state;
    return (
      <div className="navbar_layout">
        <div className="logo-wrapper">
          <img alt="logo" src={logoImg} />
        </div>
        <div className="bottom_wrapper">
          <div
            className={`plus_wrapper ${pageType === 'new' ? 'nav_active' : ''}`}
            onClick={() => this.handleNavigation('new')}
          >
            <Icon type="plus" />
          </div>
          <div
            className={`search_wrapper ${pageType === 'search' ? 'nav_active' : ''}`}
            onClick={() => this.handleNavigation('search')}
          >
            <Tooltip placement="right">
              <Search />
            </Tooltip>
          </div>
          <div className="avatar_wrapper">
            <Popover
              content={<Content agent={agent} onLogOut={this.handleLogOut} />}
              placement="rightBottom"
              trigger="click"
            >
              {!agent.avatar && <div className="avatar" onClick={this.openChooseFile} />}
              {agent.avatar && <img alt="avatar" src={`${process.env.REACT_APP_SERVER}/${agent.avatar}`} />}
            </Popover>
          </div>
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  agent: PropTypes.object,
  logOut: PropTypes.func.isRequired,
  history: PropTypes.object,
  setInitialAddress: PropTypes.func.isRequired,
};

NavBar.defaultProps = {
  agent: {},
  history: {},
};

const mapStateToProps = ({ auth }) => ({
  agent: auth.agent,
});

const mapDispatchToProps = dispatch => {
  const { logOut } = authActionCreators;
  const { setInitialAddress } = addressActionCreators;

  return bindActionCreators(
    {
      logOut,
      setInitialAddress,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectAuth(mapStateToProps, mapDispatchToProps),
  connectAddress(undefined, mapDispatchToProps),
)(NavBar);
